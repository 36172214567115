import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { push } from "connected-react-router";
import { Route, Switch } from 'react-router'

import userManager from "../utils/userManager";
import Header from '../components/header/Header';
import { AuthService } from '../services/AuthService';
import AdminContent from './admin/AdminContent';

import './appContent.css';
import CommForm from './user/form/CommForm';
import History from './user/history/History';
import ViewContent from './view/ViewContent';


class AppContent extends React.Component {
    static propTypes = {
        profile: PropTypes.object,
        location: PropTypes.object,
        language: PropTypes.string.isRequired,
        push: PropTypes.func.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.authService = new AuthService();
        this.state = {
            user: {},
            api: {},
            title: ''
        };
        this.mounted = true;
        this.query = parse(props.location.search.substr(1));
        // console.log('AppContent', { query: this.query, location: props.location });
    }

    componentDidMount() {
        this.getUser();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getUser = () => {
        this.authService.getUser().then(user => {
            if (user) {
                if (this.query.logged) {
                    this.props.push(this.props.location.pathname);
                }
            } else {
                userManager.signinRedirect({
                    ui_locales: this.props.language
                });
            }

            if (this.mounted) {
                this.setState({ user });
            }
        });
    };

    login() {
        userManager.signinRedirect({
            ui_locales: this.props.language
        });
    };

    logout() {
        userManager.signoutRedirect();
    };

    render() {
        let { location/*, profile*/ } = this.props;
        let { /*user, */title } = this.state;

        let print = location.pathname.startsWith('/view/report/print');
        let showHeader = !print;
        // console.log('AppContent.render', { profile, user });

        return (
            <div className="app-content-container">
                {showHeader &&
                    <Header
                        user={this.state.user}
                        login={() => this.login()}
                        logout={() => this.logout()}
                        title={this.context.translate(title)}
                    />
                }
                <div className={classnames("app-page-container", { print })}>
                    <Switch>
                        <Route
                            path="/admin"
                            render={(props) => <AdminContent {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            path="/view"
                            render={(props) => <ViewContent {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            exact path="/history"
                            render={(props) => <History {...props} onTitle={title => this.setState({ title })} />}
                        />
                        <Route
                            render={(props) => <CommForm {...props} onTitle={title => this.setState({ title })} />}
                        />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        profile: state.profile,
        location: state.router.location,
        language: state.localize.language
    }), {
    push
})(AppContent);