import axios from 'axios';
import qs from 'qs';
import { Constants } from '../helpers/Constants';
import { AuthService } from './AuthService';
import { toModalDate } from '../utils/date';
import { isImspError, getImspErrorCodes } from '../utils/error';

export class ApiService {

    constructor() {
        this.authService = new AuthService();
    }

    // #region /report

    getReports(date) {
        let params = {
            date
        };
        return this.callAuthorized('get', 'report', null, { params });
    }

    getMyReports(date, studentId, count) {
        let params = {
            date,
            studentId,
            count
        };
        return this.callAuthorized('get', 'report/my', null, { params });
    }

    addReport(id, date, studentId, allDayAbsence, longTermAbsence, longTermAbsenceFrom,
        longTermAbsenceTo, lateArrival, earlyDeparture, unauthorizedPickUp, unauthorizedPickUpName,
        unauthorizedPickUpId, lice, infections, childcare, aloneDeparture, other,
        courseAbsence, temperature, parasites, homeworks) {
        return this.callAuthorized('post', 'report', { id, date, studentId, allDayAbsence, longTermAbsence, longTermAbsenceFrom,
            longTermAbsenceTo, lateArrival, earlyDeparture, unauthorizedPickUp, unauthorizedPickUpName,
            unauthorizedPickUpId, lice, infections, childcare, aloneDeparture, other,
            courseAbsence, temperature, parasites, homeworks });
    }

    exportReports(params) {
        return this.callAuthorized('get', 'report/export', null, { params, responseType: 'blob' });
    }

    // #endregion

    // #region /teacher

    getTeachers() {
        return this.callAuthorized('get', 'teacher');
    }

    addTeacher(name, email, userId, file) {
        let data = new FormData();
        data.append('name', name);
        data.append('email', email);
        data.append('userId', userId);
        data.append('img', file);
        return this.callAuthorized('post', 'teacher', data, { headers: { 'content-type': 'multipart/form-data' } });
    }

    updateTeacher(id, name, email, userId, imgId, file) {
        let data = new FormData();
        data.append('name', name);
        data.append('email', email);
        data.append('userId', userId);
        if (imgId) {
            data.append('imgId', imgId);
        }
        data.append('img', file);
        return this.callAuthorized('put', `teacher/${id}`, data, { headers: { 'content-type': 'multipart/form-data' } });
    }

    deleteTeacher(id, force = false) {
        return this.callAuthorized('delete', `teacher/${id}`, { force });
    }

    // #endregion

    // #region /classroom

    getClassrooms() {
        return this.callAuthorized('get', 'classroom');
    }

    addClassroom(name, file) {
        let data = new FormData();
        data.append('name', name);
        data.append('img', file);
        return this.callAuthorized('post', 'classroom', data, { headers: { 'content-type': 'multipart/form-data' } });
    }

    updateClassroom(id, name, imgId, file) {
        let data = new FormData();
        data.append('name', name);
        if (imgId) {
            data.append('imgId', imgId);
        }
        data.append('img', file);
        return this.callAuthorized('put', `classroom/${id}`, data, { headers: { 'content-type': 'multipart/form-data' } });
    }

    deleteClassroom(id, force = false) {
        return this.callAuthorized('delete', `classroom/${id}`, { force });
    }

    // #endregion

    // #region /course

    getCourseDetail(id) {
        return this.getUnauthorized(`course/${id}`);
    }

    getCoursePreview() {
        return this.getUnauthorized('course/preview');
    }

    getCourses() {
        return this.callAuthorized('get', 'course');
    }

    getCoursesForAttendance() {
        return this.callAuthorized('get', 'course/attendance');
    }

    addCourse(name, capacity, alternate, teacherId, lessons, openFrom, classroomId, price, isExternal, description, equipments, groupIds) {
        return this.callAuthorized('post', 'course', { name, capacity, alternate, teacherId, lessons, openFrom, classroomId, price, isExternal, description, equipments, groupIds });
    }

    updateCourse(id, name, capacity, alternate, teacherId, lessons, openFrom, classroomId, price, isExternal, description, equipments, groupIds) {
        return this.callAuthorized('put', `course/${id}`, { name, capacity, alternate, teacherId, lessons, openFrom, classroomId, price, isExternal, description, equipments, groupIds });
    }

    setCourseImage(id, img) {
        let data = new FormData();
        data.append('img', img);
        return this.callAuthorized('put', `course/${id}/img`, data, { headers: { 'content-type': 'multipart/form-data' } });
    }

    deleteCourse(id, force = false) {
        return this.callAuthorized('delete', `course/${id}`, { force });
    }

    deleteCourseImage(id) {
        return this.callAuthorized('delete', `course/${id}/img`);
    }

    // #endregion

    // #region /student

    getMyStudents(withReg, withGroup) {
        let params = {
            withReg,
            withGroup
        };
        return this.callAuthorized('get', 'student/my', null, { params });
    }

    getStudents() {
        return this.callAuthorized('get', 'student');
    }

    addStudent(name, userId, groupId, free, hidden, isDiner) {
        return this.callAuthorized('post', 'student', { name, userId, groupId, free, hidden, isDiner });
    }

    updateStudent(id, name, userId, groupId, free, hidden, isDiner) {
        return this.callAuthorized('put', `student/${id}`, { name, userId, groupId, free, hidden, isDiner });
    }

    importDiners(dinerIds) {
        return this.callAuthorized('post', 'student/import/diners', { dinerIds });
    }

    // #endregion

    // #region /register

    getRegistrations() {
        return this.callAuthorized('get', 'register');
    }

    getMyRegistrations() {
        return this.callAuthorized('get', 'register/my');
    }

    addRegistration(courseId, studentId) {
        return this.callAuthorized('post', 'register/add', { courseId, studentId });
    }

    closeRegistration(id) {
        return this.callAuthorized('post', `register/${id}/close`);
    }

    deleteRegistration(id, force = false) {
        return this.callAuthorized('delete', `register/${id}`, { force });
    }

    // #endregion

    // #region /transaction

    getTransactions() {
        return this.callAuthorized('get', 'transaction');
    }

    getMyTransactions() {
        return this.callAuthorized('get', 'transaction/my');
    }

    // #endregion

    // #region /lesson

    getWeekLessons(date) {
        let params = {
            date
        };
        return this.callAuthorized('get', 'lesson/week', null, { params });
    }

    // #endregion

    // #region /user (Profile)

    getProfile() {
        return this.callAuthorized('get', 'user');
    }

    getRegInfo() {
        return this.callAuthorized('get', 'user/reg');
    }

    createProfile(id, name, email) {
        return this.callAuthorized('post', 'user', { id, name, email });
    }

    updateProfile(name) {
        return this.callAuthorized('put', `user`, { name });
    }

    // #endregion

    // #region /attendance

    getLessonAttendances(lessonId) {
        let params = {
            lessonId
        };
        return this.callAuthorized('get', 'attendance', null, { params });
    }

    setAttendance(id, presence) {
        return this.callAuthorized('put', `attendance/${id}`, { presence });
    }

    // #endregion

    // #region /email

    sendEmail(to, subject, message) {
        return this.callAuthorized('post', 'email', { to, subject, message });
    }

    sendLectorEmail(courseId, subject, message) {
        return this.callAuthorized('post', 'email/course', { courseId, subject, message });
    }

    // #endregion

    // #region /settings
    
    getSettings() {
        return this.callAuthorized('get', 'settings/communication');
    }

    setSettings(data) {
        return this.callAuthorized('post', 'settings/communication', data);
    }

    // #endregion

    // #region Others

    getMenu(date) {
        let params = {
            period: toModalDate(date)
        };
        return this.getUnauthorized('menu', { params });
    }

    getAccounts(withDiners = false, withTeacher = false) {
        let params = {
            withDiners,
            withTeacher
        };
        return this.callAuthorized('get', 'account', null, { params });
    }

    getAccountsWithDiners() {
        return this.callAuthorized('get', 'account/withDiners');
    }

    getAccountDiners() {
        return this.callAuthorized('get', 'account/diner');
    }

    getDiners() {
        return this.callAuthorized('get', 'diner');
    }

    getGroups() {
        return this.callAuthorized('get', 'group');
    }

    getOrders(date, dinerId) {
        let params = {
            period: toModalDate(date),
            dinerId
        };
        return this.callAuthorized('get', 'order', null, { params });
    }

    getDailyOrders(date) {
        let params = {
            day: toModalDate(date)
        };
        return this.callAuthorized('get', 'order/day', null, { params });
    }

    getMonthlyOrders(date) {
        let params = {
            day: toModalDate(date)
        };
        return this.callAuthorized('get', 'order/month', null, { params });
    }

    getPrices() {
        return this.callAuthorized('get', 'settings/price');
    }

    getMyHistory(period) {
        let params = {
            period: toModalDate(period)
        };
        return this.callAuthorized("get", "history/my", null, { params });
    }

    getHistory(period) {
        let params = {
            period: toModalDate(period)
        };
        return this.callAuthorized("get", "history", null, { params });
    }

    getAlergens() {
        return this.callAuthorized('get', 'alergen');
    }

    getBillings(period) {
        let params = {
            period: toModalDate(period)
        };
        return this.callAuthorized('get', 'billing', null, { params });
    }

    getBalanceNotices() {
        return this.callAuthorized('get', 'view/warning');
    }

    addDiner(name, groupId, userId, isUser, free, menu3Enabled, hidden) {
        return this.callAuthorized('post', 'diner', { name, groupId, userId, isUser, free, menu3Enabled, hidden });
    }

    addGroup(name, monthOrdersOrder) {
        return this.callAuthorized('post', 'group', { name, monthOrdersOrder });
    }

    addHistory(time, diner, type, description, amount, mealType, note) {
        return this.callAuthorized('post', 'history', { time, dinerId: diner, type, description, amount, mealType, note });
    }

    setMenu(date, lang, soup, soupAlg, menu1, menu1Alg, menu2, menu2Alg, menu3, menu3Alg, snack, snackAlg) {
        return this.callAuthorized('post', 'menu', { date, lang, soup, soupAlg, menu1, menu1Alg, menu2, menu2Alg, menu3, menu3Alg, snack, snackAlg });
    }

    setOrder(date, dinerId, menu, snack) {
        return this.callAuthorized('post', 'order', { date, dinerId, menu, snack });
    }

    setOrders(dinerId, orders) {
        return this.callAuthorized('post', 'order/many', { dinerId, orders });
    }

    setAlergen(id, cs, en) {
        return this.callAuthorized('post', 'alergen', { id, cs, en });
    }

    updateDiner(id, name, groupId, userId, isUser, free, menu3Enabled, hidden) {
        return this.callAuthorized('put', `diner/${id}`, { name, groupId, userId, isUser, free, menu3Enabled, hidden });
    }

    updateGroup(id, name, monthOrdersOrder) {
        return this.callAuthorized('put', `group/${id}`, { name, monthOrdersOrder });
    }

    updateAccount(id, roles) {
        return this.callAuthorized('put', `account/${id}`, { roles });
    }

    updateBilling(name, address, accountNumber, ico) {
        return this.callAuthorized('put', `user/billing`, { name, address, accountNumber, ico });
    }

    updateHistory(id, time, diner, type, description, amount, mealType, note) {
        return this.callAuthorized('put', `history/${id}`, { time, dinerId: diner, type, description, amount, mealType, note });
    }

    moveDiners(diners, group) {
        return this.callAuthorized('post', 'diner/move', { diners, group });
    }

    deleteAccount(id, force = false) {
        return this.callAuthorized('delete', `account/${id}`, { force });
    }

    deleteAlergen(id) {
        return this.callAuthorized('delete', `alergen/${id}`);
    }

    deletePayment(id) {
        return this.callAuthorized('delete', `history/${id}`);
    }

    deleteDiner(id, force = false) {
        return this.callAuthorized('delete', `diner/${id}`, { force });
    }

    removeMenu(date, lang) {
        return this.callAuthorized('post', 'menu/remove', { date, lang });
    }

    importMenu(data) {
        return this.callAuthorized('post', `menu/import`, data, { headers: { 'content-type': 'multipart/form-data' } });
    }

    importTransactions(data) {
        return this.callAuthorized('post', `history/import`, data, { headers: { 'content-type': 'multipart/form-data' } });
    }

    exportBillings(period) {
        let params = {
            period: toModalDate(period)
        };
        return this.callAuthorized('get', 'billing/export', null, { params, responseType: 'blob' });
    }

    exportOrdersSummary(period, language, selected) {
        let params = {
            period: toModalDate(period),
            language,
            selected
        };
        return this.callAuthorized('get', 'order/export/summary', null, { params, responseType: 'blob' });
    }

    exportMenuPdf(period, lng) {
        let params = {
            period: toModalDate(period),
            lng
        };
        return this.callAuthorized('get', 'menu/export/pdf', null, { params, responseType: 'blob' });
    }

    exportWeekCourseReportPdf(period, lng) {
        let params = {
            period: toModalDate(period),
            lng
        };
        return this.callAuthorized('get', 'course/export/week/pdf', null, { params, responseType: 'blob' });
    }

    exportDateOrders(date, group, lang) {
        let params = {
            date: toModalDate(date),
            group,
            lang
        };
        return this.callAuthorized('get', 'order/export/daily', null, { params, responseType: 'blob' });
    }

    exportMonthOrders(date, group, lang) {
        let params = {
            date: toModalDate(date),
            group,
            lang
        };
        return this.callAuthorized('get', 'order/export/monthly', null, { params, responseType: 'blob' });
    }

    recalculateBillings(period) {
        let data = {
            period: toModalDate(period)
        };
        return this.callAuthorized('post', 'billing/run', data);
    }

    getUnauthorized(path, options) {
        return axios.get(Constants.apiRoot + path, options);
    }

    callAuthorized(method, path, data, options) {
        return this.authService.getUser().then(user => {
            // console.log('checking logged user', user);
            if (user && user.access_token) {
                return this._callApi(user.access_token, method, path, data, options).catch(error => {
                    console.log(method, path, error);
                    if (isImspError(error)) {
                        console.log(getImspErrorCodes(error));
                    }
                    if (error.response && error.response.status === 401) {
                        return this.authService.renewToken().then(renewedUser => {
                            return this._callApi(renewedUser.access_token, method, path, data, options);
                        }).catch(error => {
                            console.log('renew token failed', error);
                            this.authService.login();
                        });
                    }
                    throw error;
                });
            } else if (user) {
                return this.authService.renewToken().then(renewedUser => {
                    return this._callApi(renewedUser.access_token, method, path, data, options);
                });
            } else {
                throw new Error('user is not logged in');
            }
        });
    }

    _callApi(token, method, path, data, options) {
        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        };
        options = {
            paramsSerializer: params => {
                return qs.stringify(params)
            },
            ...(options || {})
        };
        options.headers = { ...(options.headers || {}), ...headers };
        switch (method) {
            case 'put':
                return axios.put(Constants.apiRoot + path, data, options);
            case 'post':
                return axios.post(Constants.apiRoot + path, data, options);
            case 'delete':
                if (data) {
                    return axios.delete(Constants.apiRoot + path, { ...options, data });
                } else {
                    return axios.delete(Constants.apiRoot + path, options);
                }
            default:
                return axios.get(Constants.apiRoot + path, options);
        }
    }

    //#endregion
}
