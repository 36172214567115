import * as React from 'react';
import * as moment from 'moment';

const days = ['Ne', 'Po', 'Ut', 'St', 'Ct', "Pa", 'So'];

// yyyy-MM-dd
export function toModalDate(date) {
    if (!date)
        return '';
    date = toDate(date);

    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    let day = `${date.getDate()}`.padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`;
}

// dd.MM.
export function toMmDdFormat(date) {
    if (!date)
        return '';
    date = toDate(date);

    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    let day = `${date.getDate()}`.padStart(2, '0');
    return `${day}.${month}.`;
}

// yyyy-MM
export function toPeriodDate(date) {
    if (!date)
        return '';
    date = toDate(date);

    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    return `${date.getFullYear()}-${month}`;
}

// ddd d. M. yyyy
export function toLunchDate(date) {
    if (!date)
        return '';
    date = toDate(date);

    return `${days[date.getDay()]} ${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`;
}

// Date
export function toDate(date) {
    if (!date)
        return null;
    if (typeof date === 'string')
        date = parseDate(date);
    if (typeof date === 'number')
        date = new Date(date);

    return date;
}

const timespanRegex = RegExp('^\\d{2}:\\d{2}:\\d{2}$');
export function parseDate(dateString) {
    if (timespanRegex.test(dateString)) {
        dateString = `1900-01-01T${dateString}`;
    }
    let result = moment(dateString).toDate().getTime();
    return result;
}

// d. M.
export function toLunchShortDate(date) {
    if (!date)
        return '';
    date = toDate(date);

    return `${date.getDate()}. ${date.getMonth() + 1}.`;
}

// ddd
export function toLunchDay(date) {
    if (!date)
        return '';
    date = toDate(date);

    return days[date.getDay()];
}

// yyyy-MM-dd HH-mm
export function toModalDateTime(dt) {
    if (!dt)
        return '';
    dt = toDate(dt);

    let month = `${dt.getMonth() + 1}`.padStart(2, '0');
    let day = `${dt.getDate()}`.padStart(2, '0');
    let hour = `${dt.getHours()}`.padStart(2, '0');
    let min = `${dt.getMinutes()}`.padStart(2, '0');
    return `${dt.getFullYear()}-${month}-${day} ${hour}:${min}`;
}

// d. M. yyyy  HH:mm
export function toPaymentDateTime(dt) {
    if (!dt)
        return '';
    dt = toDate(dt);

    let hour = `${dt.getHours()}`.padStart(2, '0');
    let min = `${dt.getMinutes()}`.padStart(2, '0');
    return <span>{`${dt.getDate()}. ${dt.getMonth() + 1}. ${dt.getFullYear()}`}&nbsp;&nbsp;{`${hour}:${min}`}</span>;
}

// d. M. yyyy  HH:mm
export function toDateTimeFormat(dt) {
    if (!dt)
        return '';
    dt = toDate(dt);

    let hour = `${dt.getHours()}`.padStart(2, '0');
    let min = `${dt.getMinutes()}`.padStart(2, '0');
    return `${dt.getDate()}. ${dt.getMonth() + 1}. ${dt.getFullYear()}  ${hour}:${min}`;
}

// HH:mm
export function toTimeFormat(dt) {
    if (!dt)
        return '';
    dt = toDate(dt);

    let hour = `${dt.getHours()}`.padStart(2, '0');
    let min = `${dt.getMinutes()}`.padStart(2, '0');
    return `${hour}:${min}`;
}

// H:mm
export function toTimeHmmFormat(dt) {
    if (!dt)
        return '';
    dt = toDate(dt);

    let hour = `${dt.getHours()}`;
    let min = `${dt.getMinutes()}`.padStart(2, '0');
    return `${hour}:${min}`;
}

// HH:mm:ss
export function toTimeHHmmssFormat(dt) {
    if (!dt)
        return '';
    dt = toDate(dt);

    let hour = `${dt.getHours()}`.padStart(2, '0');
    let min = `${dt.getMinutes()}`.padStart(2, '0');
    let sec = `${dt.getSeconds()}`.padStart(2, '0');
    return `${hour}:${min}:${sec}`;
}

// d. M. yyyy
export function toStandardDmySpan(dt) {
    if (!dt)
        return '';
    dt = toDate(dt);

    return <span>{`${dt.getDate()}. ${dt.getMonth() + 1}. ${dt.getFullYear()}`}</span>;
}

// ddd HH:mm
export function toDddHhMm(dt) {
    if (!dt)
        return '';
    dt = toDate(dt);

    let day = days[dt.getDay()];
    let hour = `${dt.getHours()}`.padStart(2, '0');
    let min = `${dt.getMinutes()}`.padStart(2, '0');
    return `${day} ${hour}:${min}`;
}

export function toTerm(dt) {
    if (!dt)
        return '';
    dt = toDate(dt);

    let month = dt.getMonth() + 1;
    if (month >= 9) {
        return { id: 1, name: 'I' };
    }
    if (month <= 3) {
        return { id: 2, name: 'II' };
    }
    if (month <= 6) {
        return { id: 3, name: 'III' };
    }
    return { id: 0, name: '-' };
}

export function prevDay(value) {
    return new Date(value.getFullYear(), value.getMonth(), value.getDate() - 1);
}

export function nextDay(value) {
    return new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1);
}

export function prevYear(value) {
    return new Date(value.getFullYear() - 1, value.getMonth(), value.getDate());
}

export function nextYear(value) {
    return new Date(value.getFullYear() + 1, value.getMonth(), value.getDate());
}

export function today() {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export function getDtMinutes() {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), 0, 0);
}

/* Vraci datum, ktere je o n dni vetsi nez datum d. */
export function addDay(d, n) {
    d = new Date(d);
    var diff = d.getDate() + n;
    return new Date(d.setDate(diff));
}