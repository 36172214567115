import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ApiService } from '../../../services/ApiService';
import { nextDay, today, toTimeHHmmssFormat, toModalDate } from '../../../utils/date';
import { parseDate, toModalDateTime } from '../../../utils/date';

import './commForm.scss';
import { errorEquals, ERR_INVALID_DATE } from '../../../utils/error';
import ReactTooltip from 'react-tooltip';
import { DateControl } from '../../../components/dateControl/dateControl';
import Modal from '../../../components/Modal';


export const defaultReport = {
    allDayAbsence: false,
    longTermAbsence: false,
    longTermAbsenceFrom: '',
    longTermAbsenceTo: '',
    lateArrival: '',
    earlyDeparture: '',
    unauthorizedPickUp: false,
    unauthorizedPickUpName: '',
    unauthorizedPickUpId: '',
    lice: false,
    infections: '',
    childcare: false,
    aloneDeparture: false,
    other: '',
    courseAbsence: false,
    temperature: false,
    parasites: false,
    homeworks: false
}

function initDate(limit = '09:00:00') {
    let result = today();
    if (toTimeHHmmssFormat(new Date()) >= limit) {
        result = nextDay(result);
    }
    return result;
}

class CommForm extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props);

        this.state = {
            date: initDate(),
            student: '',
            students: [],
            group: '',
            data: { ...defaultReport },
            settings: {},
            cmOpen: false,
            showLices: false,
            showParasites: false
        };
        this.mounted = false;
        this.api = new ApiService();

        this.yesno = [
            { label: 'Codelist.No', value: false },
            { label: 'Codelist.Yes', value: true }
        ];
        this.lateArrivals = [
            { label: '', value: '' },
            { label: '9:00', value: '09:00:00' },
            { label: '9:15', value: '09:15:00' },
            { label: '9:30', value: '09:30:00' },
            { label: '9:45', value: '09:45:00' },
            { label: '10:00', value: '10:00:00' },
            { label: '10:15', value: '10:15:00' },
            { label: '10:30', value: '10:30:00' },
            { label: '10:45', value: '10:45:00' },
            { label: '11:00', value: '11:00:00' },
            { label: '11:15', value: '11:15:00' },
            { label: '11:30', value: '11:30:00' },
            { label: '11:45', value: '11:45:00' },
            { label: '12:00', value: '12:00:00' },
            { label: '12:15', value: '12:15:00' },
            { label: '12:30', value: '12:30:00' },
            { label: '12:45', value: '12:45:00' },
            { label: '13:00', value: '13:00:00' },
            { label: '13:15', value: '13:15:00' },
            { label: '13:30', value: '13:30:00' }
        ];
        this.earlyDepartures = [
            { label: '', value: '' },
            { label: '10:00', value: '10:00:00' },
            { label: '10:15', value: '10:15:00' },
            { label: '10:30', value: '10:30:00' },
            { label: '10:45', value: '10:45:00' },
            { label: '11:00', value: '11:00:00' },
            { label: '11:15', value: '11:15:00' },
            { label: '11:30', value: '11:30:00' },
            { label: '11:45', value: '11:45:00' },
            { label: '12:00', value: '12:00:00' },
            { label: '12:15', value: '12:15:00' },
            { label: '12:30', value: '12:30:00' },
            { label: '12:45', value: '12:45:00' },
            { label: '13:00', value: '13:00:00' },
            { label: '13:15', value: '13:15:00' },
            { label: '13:30', value: '13:30:00' },
            { label: '13:45', value: '13:45:00' },
            { label: '14:00', value: '14:00:00' },
            { label: '14:15', value: '14:15:00' },
            { label: '14:30', value: '14:30:00' }
        ];
    }

    componentWillMount() {
        this.loadStudents();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Form.Title');
        this.loadSettings();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadSettings() {
        this.api.getSettings()
            .then(response => {
                if (this.mounted) {
                    this.setState({ settings: response.data }, () => {
                        this.changeDate(initDate(response.data.sendLimit));
                    });
                }
            })
            .catch(error => {
                console.log('CommForm.loadSettings error', error);
            })
    }

    loadStudents() {
        this.api.getMyStudents(false, true)
            .then(response => {
                if (this.mounted) {
                    let students = _.mapKeys(response.data || {}, 'id');
                    this.setState({ students }, () => {
                        if (Object.keys(students).length) {
                            this.onStudentChanged(students[Object.keys(students)[0]].id);
                        }
                    });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadReport() {
        let { student, date } = this.state;
        if (!date || !student) {
            return;
        }

        this.api.getMyReports(toModalDateTime(date), student)
            .then(response => {
                if (this.mounted) {
                    if (response.data && response.data.length && toModalDate(response.data[0].date) === toModalDate(this.state.date)) {
                        console.log('report loaded', response.data[0]);
                        this.setState({ data: { ...defaultReport, ...response.data[0] } }, () => ReactTooltip.rebuild());
                    }
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    onStudentChanged(student) {
        this.setState({ student }, () => this.onDateStudentChanged());
    }

    onDateStudentChanged() {
        this.reset();
    }

    changeDate(date) {
        // console.log('date change', date);
        this.setState({ date }, () => this.onDateStudentChanged());
    }

    send() {
        let { date, student, data, settings } = this.state;
        let { id, allDayAbsence, longTermAbsence, longTermAbsenceFrom, longTermAbsenceTo,
            lateArrival, earlyDeparture, unauthorizedPickUp, unauthorizedPickUpName,
            unauthorizedPickUpId, lice, infections, childcare, aloneDeparture, other,
            courseAbsence, temperature, parasites, homeworks } = data;

        this.api.addReport(id, toModalDateTime(date), student, allDayAbsence, longTermAbsence,
            longTermAbsenceFrom, longTermAbsenceTo, lateArrival, earlyDeparture, unauthorizedPickUp,
            unauthorizedPickUpName, unauthorizedPickUpId, lice, infections, childcare, aloneDeparture,
            other, courseAbsence, temperature, parasites, homeworks)
            .then(response => {
                if (this.mounted) {
                    this.setState({ data: { ...defaultReport, ...response.data }, cmOpen: true });
                    toastr.success(this.context.translate('Form.SuccessSend'), null, { timeOut: 10000 });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    if (errorEquals(error, ERR_INVALID_DATE)) {
                        toastr.error(this.context.translate('Form.InvalidDateError').replace('{time}', settings.sendLimit));
                    } else {
                        toastr.error(this.context.translate('Err.System'));
                    }
                }
            });
    }

    reset() {
        this.setState({ data: { ...defaultReport } }, () => {
            this.loadReport();
        });
    }

    render() {
        let { date, data, students, student, settings, cmOpen, showLices, showParasites } = this.state;
        let { translate } = this.context;

        var mDate = moment(date).startOf('date');
        var mNow = moment();
        var mToday = moment().startOf('date');
        var mLimit = moment(`${mDate.format('YYYY-MM-DD')} ${settings.sendLimit}`);
        var icoType = mDate.isAfter(mToday)
            ? 'future'
            : mNow.isSameOrAfter(mLimit)
                ? 'past'
                : 'today';

        // console.log('render ', pok);

        return (
            <div className="user-page-container communication-report">
                <div className="form-group">
                    <label>{translate('Form.Date')}</label>
                    <DateControl
                        date={date}
                        onChange={date => this.changeDate(date)}
                        onChangeRaw={e => e.preventDefault()}
                    />
                </div>
                <div className="form-group">
                    <label> </label>
                    <div className="date-info">
                        <span className={`date-info-ico ${icoType}`}><i className={classnames('fa', { 'fa-info': icoType === 'future', 'fa-exclamation': icoType === 'today', 'fa-times': icoType === 'past' })} /></span>
                        <span dangerouslySetInnerHTML={{ __html: translate('Form.DateInfo').replace('{time}', mLimit.format('HH:mm')) }}></span>
                    </div>
                </div>
                <div className="form-group">
                    <label>{translate('Form.Student')}</label>
                    <select
                        className="form-control"
                        value={student}
                        onChange={e => this.onStudentChanged(e.target.value)}
                    >
                        {_.map(students, o => (
                            <option key={o.id} value={o.id}>{o.name}</option>
                        ))}
                    </select>
                </div>
                <div className="delimiter"></div>
                <div className={classnames('form-group', { change: data.allDayAbsence !== defaultReport.allDayAbsence })}>
                    <label>{translate('Form.AllDayAbsence')}</label>
                    <select
                        className="form-control"
                        value={data.allDayAbsence}
                        onChange={e => this.setState({ data: { ...data, allDayAbsence: e.target.value === 'true' } })}
                    >
                        {this.yesno.map(o => (
                            <option key={o.value} value={o.value}>{translate(o.label)}</option>
                        ))}
                    </select>
                </div>
                <div className={classnames('form-group', { change: data.longTermAbsence !== defaultReport.longTermAbsence })}>
                    <label>{translate('Form.LongTermAbsence')}</label>
                    <select
                        className="form-control"
                        value={data.longTermAbsence}
                        onChange={e => this.setState({ data: { ...data, longTermAbsence: e.target.value === 'true' } }, () => ReactTooltip.rebuild())}
                    >
                        {this.yesno.map(o => (
                            <option key={o.value} value={o.value}>{translate(o.label)}</option>
                        ))}
                    </select>
                </div>
                {data.longTermAbsence &&
                    <div className="form-group dependent change">
                        <label>{translate('Form.From')}</label>
                        <DatePicker
                            className="form-control"
                            dateFormat={["d. M. yyyy", "d.M.yyyy", "d. M.yyyy", "d.M. yyyy"]}
                            selected={parseDate(data.longTermAbsenceFrom)}
                            onChange={date => this.setState({ data: { ...data, longTermAbsenceFrom: toModalDateTime(date) } })}
                            placeholderText="d. M. yyyy"
                            strictParsing={true}
                            isClearable
                        />
                    </div>
                }
                {data.longTermAbsence &&
                    <div className="form-group dependent change">
                        <label>{translate('Form.To')}</label>
                        <DatePicker
                            className="form-control"
                            dateFormat={["d. M. yyyy", "d.M.yyyy", "d. M.yyyy", "d.M. yyyy"]}
                            selected={parseDate(data.longTermAbsenceTo)}
                            onChange={date => this.setState({ data: { ...data, longTermAbsenceTo: toModalDateTime(date) } })}
                            placeholderText="d. M. yyyy"
                            strictParsing={true}
                            isClearable
                        />
                    </div>
                }
                {/*
                    <div className={classnames('form-group dependent', { change: data.homeworks != defaultReport.homeworks })}>
                        <label data-tip={translate('Form.HomeworksInfo')}>{translate('Form.Homeworks')} <i className="fa fa-info-circle" /></label>
                        <select
                            className="form-control"
                            value={data.homeworks}
                            onChange={e => this.setState({ data: { ...data, homeworks: e.target.value === 'true' } })}
                        >
                            {this.yesno.map(o => (
                                <option key={o.value} value={o.value}>{translate(o.label)}</option>
                            ))}
                        </select>
                            </div>
                        */}
                {data.longTermAbsence &&
                    <div className="form-group">
                        <label> </label>
                        <div className="homework-info">
                            <span>{translate('Form.HwInfo')}</span>
                        </div>
                    </div>
                }
                {data.longTermAbsence &&
                    <div className="delimiter"></div>
                }
                <div className={classnames('form-group', { change: data.lateArrival !== defaultReport.lateArrival })}>
                    <label>{translate('Form.LateArrival')}</label>
                    <select
                        className="form-control"
                        value={data.lateArrival}
                        onChange={e => this.setState({ data: { ...data, lateArrival: e.target.value } })}
                    >
                        {this.lateArrivals.map(o => (
                            <option key={o.value} value={o.value}>{o.label}</option>
                        ))}
                    </select>
                </div>
                <div className={classnames('form-group', { change: data.earlyDeparture !== defaultReport.earlyDeparture })}>
                    <label>{translate('Form.EarlyDeparture')}</label>
                    <select
                        className="form-control"
                        value={data.earlyDeparture}
                        onChange={e => this.setState({ data: { ...data, earlyDeparture: e.target.value } })}
                    >
                        {this.earlyDepartures.map(o => (
                            <option key={o.value} value={o.value}>{o.label}</option>
                        ))}
                    </select>
                </div>
                <div className={classnames('form-group', { change: data.courseAbsence !== defaultReport.courseAbsence })}>
                    <label>{translate('Form.CourseAbsence')}</label>
                    <select
                        className="form-control"
                        value={data.courseAbsence}
                        onChange={e => this.setState({ data: { ...data, courseAbsence: e.target.value === 'true' } })}
                    >
                        {this.yesno.map(o => (
                            <option key={o.value} value={o.value}>{translate(o.label)}</option>
                        ))}
                    </select>
                </div>
                <div className={classnames('form-group', { change: data.infections !== defaultReport.infections })}>
                    <label >{translate('Form.Infection')}</label>
                    <input
                        className="form-control"
                        type="text"
                        autoComplete="off"
                        value={data.infections}
                        onChange={e => this.setState({ data: { ...data, infections: e.target.value } })}
                    />
                </div>
                <div className={classnames('form-group', { change: data.temperature !== defaultReport.temperature })}>
                    <label data-tip={translate('Form.TemperatureInfo')}>{translate('Form.Temperature')} <i className="fa fa-info-circle" /></label>
                    <select
                        className="form-control"
                        value={data.temperature}
                        onChange={e => this.setState({ data: { ...data, temperature: e.target.value === 'true' } })}
                    >
                        {this.yesno.map(o => (
                            <option key={o.value} value={o.value}>{translate(o.label)}</option>
                        ))}
                    </select>
                </div>
                {showLices &&
                    <div className={classnames('form-group', { change: data.lice !== defaultReport.lice })}>
                        <label>{translate('Form.Lice')}</label>
                        <select
                            className="form-control"
                            value={data.lice}
                            onChange={e => this.setState({ data: { ...data, lice: e.target.value === 'true' } })}
                        >
                            {this.yesno.map(o => (
                                <option key={o.value} value={o.value}>{translate(o.label)}</option>
                            ))}
                        </select>
                    </div>
                }
                {showParasites &&
                    <div className={classnames('form-group', { change: data.parasites !== defaultReport.parasites })}>
                        <label>{translate('Form.Parasites')}</label>
                        <select
                            className="form-control"
                            value={data.parasites}
                            onChange={e => this.setState({ data: { ...data, parasites: e.target.value === 'true' } })}
                        >
                            {this.yesno.map(o => (
                                <option key={o.value} value={o.value}>{translate(o.label)}</option>
                            ))}
                        </select>
                    </div>
                }
                <div className={classnames('form-group', { change: data.unauthorizedPickUp !== defaultReport.unauthorizedPickUp })}>
                    <label>{translate('Form.UnauthorizedPickUp')}</label>
                    <select
                        className="form-control"
                        value={data.unauthorizedPickUp}
                        onChange={e => this.setState({ data: { ...data, unauthorizedPickUp: e.target.value === 'true' } })}
                    >
                        {this.yesno.map(o => (
                            <option key={o.value} value={o.value}>{translate(o.label)}</option>
                        ))}
                    </select>
                </div>
                {data.unauthorizedPickUp &&
                    <div className="form-group dependent change">
                        <label >{translate('Form.UnauthorizedPickUpName')}</label>
                        <input
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            value={data.unauthorizedPickUpName}
                            onChange={e => this.setState({ data: { ...data, unauthorizedPickUpName: e.target.value } })}
                        />
                    </div>
                }
                {data.unauthorizedPickUp &&
                    <div className="form-group dependent change">
                        <label >{translate('Form.UnauthorizedPickUpId')}</label>
                        <input
                            className="form-control"
                            type="text"
                            autoComplete="off"
                            value={data.unauthorizedPickUpId}
                            onChange={e => this.setState({ data: { ...data, unauthorizedPickUpId: e.target.value } })}
                        />
                    </div>
                }
                {data.unauthorizedPickUp &&
                    <div className="delimiter"></div>
                }
                <div className={classnames('form-group', { change: data.childcare !== defaultReport.childcare })}>
                    <label data-tip={translate('Form.ChildcareInfo')}>{translate('Form.Childcare')} <i className="fa fa-info-circle" /></label>
                    <select
                        className="form-control"
                        value={data.childcare}
                        onChange={e => this.setState({ data: { ...data, childcare: e.target.value === 'true' } })}
                    >
                        {this.yesno.map(o => (
                            <option key={o.value} value={o.value}>{translate(o.label)}</option>
                        ))}
                    </select>
                </div>
                <div className={classnames('form-group', { change: data.aloneDeparture !== defaultReport.aloneDeparture })}>
                    <label>{translate('Form.AloneDeparture')}</label>
                    <select
                        className="form-control"
                        value={data.aloneDeparture}
                        onChange={e => this.setState({ data: { ...data, aloneDeparture: e.target.value === 'true' } })}
                    >
                        {this.yesno.map(o => (
                            <option key={o.value} value={o.value}>{translate(o.label)}</option>
                        ))}
                    </select>
                </div>
                <div className={classnames('form-group', { change: data.other !== defaultReport.other })}>
                    <label >{translate('Form.Other')}</label>
                    <textarea
                        className="form-control"
                        rows="5"
                        value={data.other}
                        onChange={e => this.setState({ data: { ...data, other: e.target.value } })}
                    ></textarea>
                </div>
                <div className="buttons">
                    <button
                        className="btn btn-light"
                        onClick={() => this.reset()}
                    >{translate('Btn.Reset')}</button>
                    <button
                        className="btn btn-imsp"
                        onClick={() => this.send()}
                    >{translate('Btn.Send')}</button>
                </div>
                <div className="form-group">
                    {translate('Form.Gdpr')}
                </div>
                <Modal
                    modalClass="sent-modal"
                    title={translate('Form.Submited')}
                    closeOnClick={false}
                    showCloseButton={true}
                    onClose={() => this.setState({ cmOpen: false })}
                    onSubmit={() => this.setState({ cmOpen: false })}
                    show={cmOpen}
                    buttons={(
                        <button
                            className="btn btn-light"
                            onClick={() => this.setState({ cmOpen: false })}
                        >{this.context.translate('Btn.Close')}</button>
                    )}
                >
                    <div className="ta-c">{translate('Form.SubmitText')}</div>
                </Modal>
                <ReactTooltip
                    className="imsp-tooltip multiline"
                    place="right"
                    type="custom"
                />
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(CommForm);