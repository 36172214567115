import _ from 'lodash';

export const ROLE_ADMIN = 1;
export const ROLE_VIEWER = 2;

export function isAdmin(profile) {
    return profile
        && profile.loaded
        && profile.data
        && profile.data.roles
        && _.find(profile.data.roles, { roleId: ROLE_ADMIN });
}

export function isViewer(profile) {
    return profile
        && profile.loaded
        && profile.data
        && profile.data.roles
        && _.find(profile.data.roles, { roleId: ROLE_VIEWER });
}

export function isTeacher(profile) {
    return profile
        && profile.loaded
        && profile.data
        && profile.data.teacher;
}
