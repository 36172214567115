import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import i18n from '../i18n/i18n';
import AppContent from './AppContent';
import { loadProfile } from '../redux/actions/profileAction';

import './app.scss';

class App extends React.Component {
    static propTypes = {
        language: PropTypes.string.isRequired,
        loadProfile: PropTypes.func.isRequired
    }

    static childContextTypes = {
		translate: PropTypes.func.isRequired,
		getTranslationResource: PropTypes.func.isRequired
	};

    constructor(props) {
        super(props);

        this.i18n = i18n(this.props.language);

        this.translate = this.translate.bind(this);
    }

    componentWillMount() {
        this.props.loadProfile();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.language !== nextProps.language) {
			this.i18n.changeLanguage(nextProps.language);

			// this.props.updateIntlLanguage(nextProps.language);
            // let _self = this;
			// setTimeout(() => {
			// 	_self.props.updateIntlLanguage(nextProps.language);
			// }, 50);
		}
    }

    getChildContext() {
		return {
            translate: this.translate,
            getTranslationResource: this.getTranslationResource
		};
	}

    translate(key, options) {
		if (options) {
			return this.i18n.t(key, options);
		}

		return this.i18n.t(key);
    }
    
    getTranslationResource(key) {
		return this.i18n.getResource(this.props.language, "translation", key);
	}

    render() {
        // console.log('props', this.props);

        return (
            <>
                <AppContent />
            </>
        );
    }
}

export default connect(
    state => ({
        router: state.router,
        oidc: state.oidc,
        profile: state.profile,
        language: state.localize.language
    }), {
        loadProfile
    })(App);
