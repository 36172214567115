const cs = {
	"Account": {
		"Name": "Jméno",
		"Email": "E-mail",
		"Address": "Adresa",
		"AccNum": "Číslo účtu",
		"Diners": "Strávníci",
		"Roles": "Oprávnění",
		"SetRole": "Nastavit oprávnění",
		"IsTeacher": "Učitel",
		"SetAsDiner": "Nastavit jako strávníka",
		"WithoutDiner": "Bez strávníka",
		"Delete": "Smazat",
		"DeletedSuccessfuly": "Účet byl úspěšně smazán.",
		"ConfirmDelete": "Opravdu chcete smazat vybraný účet?",
		"ConfirmForceDelete": "Pro vybraný účet již existují data. Opravdu jej chcete smazat?"
	},
	"Alergen": {
		"Id": "ID",
		"Cs": "Česky",
		"En": "Anglicky",
		"ModalTitle": "Alergen",
		"SaveSuccess": "Alergen byl úspěšně uložen.",
		"DeleteSuccess": "Alergen byl úspěšně smazán."
	},
	"Billing": {
		"User": "Uživatel",
		"Diner": "Strávník",
		"CreditBefore": "Počáteční stav kreditu",
		"Menu": "Hlavní jídla",
		"Snack": "Svačiny",
		"Total": "Celkem",
		"Credit": "Konečný stav kreditu",
		"Recalculate": "Přepočítat",
		"ConfirmRecalculate": "Opravdu chcete spustit přepočet vyúčtování za {period} a všech následujících vyúčtování?",
		"RecalculateSent": "Požadavek na přepočet byl úspěšně odeslán. Na minutku si odpočiňte a pak obnovte stránku."
	},
	"Btn": {
		"Add": "Přidat",
		"Update": "Upravit",
		"Delete": "Smazat",
		"Cancel": "Zrušit",
		"Save": "Uložit",
		"Export": "Export",
		"Import": "Import",
		"Close": "Zavřít",
		"Select": "Vybrat",
		"Send": "Odeslat",
		"Print": "Tisk",
		"Back": "Zpět",
		"Create": "Vytvořit",
		"Finish": "Ukončit",
		"Reset": "Reset"
	},
	"Codelist": {
		"All": "< vše >",
		"ChoseDiner": "< vyber strávníka >",
		"Yes": "Ano",
		"No": "Ne"
	},
	"Daily": {
		"Diner": "Strávník",
		"Group": "Skupina"	
	},
	"Days": {
		"Basic": {
			"0": "Neděle",
			"1": "Pondělí",
			"2": "Úterý",
			"3": "Středa",
			"4": "Čtvrtek",
			"5": "Pátek",
			"6": "Sobota"
		},
		"Short": {
			"0": "Ne",
			"1": "Po",
			"2": "Út",
			"3": "St",
			"4": "Čt",
			"5": "Pá",
			"6": "So"
		}
	},
	"Diner": {
		"Name": "Jméno",
		"Group": "Skupina",
		"Account": "Účet",
		"UserDiner": "Je uživatel",
		"NotPay": "All inclusive",
		"GlutenFree": "Bezlepkové menu",
		"Move": "Přesunout",
		"ModalTitle": "Strávník",
		"MoveModalTitle": "Přesunout strávníky",
		"MoveSubmit": "Přesunout",
		"MoveSuccess": "Strávníci byli úspěšně přesunuti.",
		"PaymentId": "Variabilní symbol",
		"Balance": "Stav konta",
		"Unposted": "Nezúčtované objednávky",
		"ConfirmDelete": "Opravdu chcete smazat vybraného strávníka?",
		"DeleteSuccess": "Strávník byl úspěšně smazán.",
		"DeleteDependencies": "Strávník, které ho se chystáte smazat, již obsahuje data (objednávky, platby, vyúčtování). Opravdu jej chcete smazat i se všemi daty?",
		"Hidden": "Skrýt v objednávkách"
	},
	"Emails": {
		"To": "Komu",
		"Subject": "Předmět",
		"Message": "Zpráva",
		"SelectTo": "Vyber příjemce",
		"All": "Všichni",
		"SendSuccess": "E-mail byl úspěšně odeslán."
	},
	"Filter": {
		"Period": "Období",
		"Diner": "Strávník",
		"Group": "Skupina",
		"Date": "Datum",
		"Title": "Filtr",
		"AllInclusive": "All inclusive",
		"IsUser": "Je uživatel",
		"Name": "Jméno",
		"Type": "Typ",
		"Course": "Kroužek",
		"Lesson": "Lekce",
		"Student": "Student",
		"Closed": "Zobrazit ukončené",
		"Alternate": "Náhradník",
		"Account": "Účet",
		"Term": "Term"
	},
	"Group": {
		"Title": "Skupina",
		"Name": "Název",
		"Order": "Pořadí v Měsíčním přehledu",
		"AddSuccess": "Skupina byla úspěšně vytvořena.",
		"EditSuccess": "Skupina byla úspěšně upravena."
	},
	"Header": {
		"Menu": "Nabídka",
		"Courses": "Kroužky",
		"Login": "Přihlásit",
		"Register": "Registrovat",
		"Payments": "Platby",
		"Logout": "Odhlásit",
		"Administration": "Správa aplikace",
		"Accounts": "Uživatelské účty",
		"Diners": "Strávníci",
		"Groups": "Skupiny",
		"Lunch": "Jídelní lístek",
		"Profile": "Profil",
		"AdminPayments": "Platby",
		"Billing": "Vyúčtování",
		"Alergens": "Alergeny",
		"Settings": "Nastavení",
		"Reports": "Přehledy",
		"OrderSummary": "Počty objednávek",
		"OrderDay": "Objednávky strávníků",
		"OrderMonth": "Měsíční objednávky",
		"Emails": "Zasílání e-mailů",
		"About": {
			"Title": "O aplikaci",
			"Name": "Název",
			"App": "IMSP Daily Communication Report",
			"Version": "Verze",
			"Copyright": "© 2020 Copyright by IMSP. All right reserved.",
			"By": "Vytvořil"
		},
		"Notice": "Zaslané upozornění",
		"Teachers": "Učitelé",
		"Classrooms": "Školní místnosti",
		"Students": "Studenti",
		"ReportWeek": "Týdenní přehled",
		"LectorAttendance": "Docházka",
		"Registrations": "Registrace",
		"Overview": "Přehled",
		"AttendanceView": "Docházka",
		"LectorEmail": "Zaslat e-mail",
		"Form": "Formulář",
		"History": "Historie",
		"Report": "Report"
	},
	"ImportResult": {
		"Title": "Výsledek importu"
	},
	"Lunch": {
		"SaveSuccess": "Menu bylo úspěšně nastaveno.",
		"ImportSuccess": "Bylo úspěšně importováno {{count}} záznamů.",
		"ImportError": "Bylo importováno {{count}} záznamů, {{errCount}} skončilo chybou:</br>{{errors}}",
		"Period": "Období",
		"Lang": "Jazyk",
		"DownloadImport": "Stáhnout vzorový soubor",
		"ColDate": "Datum",
		"ColLang": "Jazyk",
		"ColSoup": "Polévka",
		"RemoveSuccess": "Menu bylo úspěšně smazáno.",
		"ConfirmRemove": "Opravdu chcete smazat vybraný záznam?"
	},
	"LunchMenu": {
		"NoDiner": "Vítejte v IMSP Obědovém systému. Zatím nemáte ke svému účtu nastaveného žádného strávníka, takže nemůžete objednávat.",
		"Balance": "Stav konta",
		"UnpostedOrders": "Nezaúčtované objednávky",
		"PaymentInfo": "Zobrazit údaje pro platbu",
		"Alergens": "Alergeny",
		"NoAlergens": "Alergeny nejsou zadány",
		"AlergensNotParsed": "Alergeny nebyly rozpoznány",
		"Modal": {
			"Title": "Platební údaje",
			"Number": "Číslo účtu",
			"Symbol": "Variabilní symbol",
			"Amount": "Částka",
			"Note": "Částka platby je pouze orientační údaj, vypočítaný na základě stavu konta a objednávek v daném měsíci.",
			"Close": "Zavřít",
			"Ss": "Specifický symbol"
		},
		"ExportMenu": "Export jídelníčku",
		"MissingBillingInfo": {
			"Part1": "Vážený uživateli, nemáte vyplněny fakturační údaje. Nastavte si je prosím v sekci ",
			"Part2": ". Pokud nebudete mít vypněny fakturační údaje, nebude vám dovoleno objednávat obědy."
		},
		"LimitExceeded": "Byla překročena maximální hodnota dluhu.",
		"HidePast": "Skrýt staré"
	},
	"MealType": {
		"0": "Polévka",
		"1": "Masové menu",
		"2": "Vegetariánské menu",
		"3": "Bezlepkové menu",
		"4": "Svačina"
	},
	"Monthly": {
		"Diner": "Strávník",
		"Group": "Skupina",
		"Print": {
			"Title": "Měsíční přehled objednávek",
			"All": "všechny"
		}
	},
	"Months": {
		"Basic": {
			"1": "Leden",
			"2": "Únor",
			"3": "Březen",
			"4": "Duben",
			"5": "Květen",
			"6": "Červen",
			"7": "Červenec",
			"8": "Srpen",
			"9": "Září",
			"10": "Říjen",
			"11": "Listopad",
			"12": "Prosinec"
		},
		"OfDate": {
			"1": "Ledna",
			"2": "Února",
			"3": "Března",
			"4": "Dubna",
			"5": "Května",
			"6": "Června",
			"7": "Července",
			"8": "Srpna",
			"9": "Září",
			"10": "Října",
			"11": "Listopadu",
			"12": "Prosince"
		},
		"Short": {
			"1": "Led",
			"2": "Úno",
			"3": "Bře",
			"4": "Dub",
			"5": "Kvě",
			"6": "Čvn",
			"7": "Čvc",
			"8": "Srp",
			"9": "Zář",
			"10": "Říj",
			"11": "Lis",
			"12": "Pro"
		}
	},
	"Notice": {
		"Time": "Čas odeslání",
		"Period": "Období",
		"Result": "Výsledek",
		"CountText": "Počet dlužníků",
		"Ok": "Vše OK",
		"Errors": "Chyby",
		"Diner": "Strávník",
		"Balance": "Stav konta",
		"Error": "Chyba"
	},
	"OrderView": {
		"Date": "Datum",
		"Count": "Počet"
	},
	"Payment": {
		"TypePayment": "Platba",
		"TypeDeposite": "Vklad",
		"Time": "Čas",
		"Diner": "Strávník",
		"Type": "Typ",
		"Description": "Popis",
		"Amount": "Částka",
		"SumaPayment": "Celkem platby",
		"AddTitle": "Nový záznam",
		"SaveSuccess": "Záznam byl úspěšně uložen.",
		"ImportSuccess": "Bylo úspěšně importováno {{count}} záznamů.",
		"ImportError": "Bylo importováno {{count}} záznamů, {{errCount}} skončilo chybou:</br>{{errors}}",
		"Balance": "Stav konta",
		"TotalDeposite": "Celkem vklady",
		"ImportExample": "Ukázový importní soubor",
		"Import": "Import vkladů",
		"ConfirmDelete": "Opravdu chcete smazat vybraný záznam?",
		"MealType": "Jídlo",
		"EditTitle": "Úprava záznamu"
	},
	"Profile": {
		"Profile": "Profil",
		"Name": "Jméno",
		"Email": "E-mail",
		"Invoice": "Fakturační údaje",
		"Address": "Adresa",
		"Account": "Číslo účtu",
		"Ico": "IČO",
		"ProfileSuccess": "Profil byl uložen.",
		"BillingSuccess": "Fakturační údaje uloženy."
	},
	"Settings": {
		"MealPrice": "Ceny jídel",
		"Other": "Ostatní parametry",
		"AccountNumber": "Číslo účtu",
		"DebitLimit": "Limit nedoplatku",
		"MealPriceSuccess": "Ceny jídel byly úspěšně uloženy.",
		"OtherSuccess": "Ostatní parametry byly úspěšně uloženy.",
		"SpecificSymbol": "Specifický symbol",
		"SendingOrders": "Zasílání objednávek",
		"SoEnabled": "Aktivní",
		"SoTo": "Komu",
		"SoCc": "Kopie",
		"SendOrdersSuccess": "Zasílání objednávek bylo úspěšně uloženo.",
		"SendBalanceWarning": "Zasílání upozornění na záporný stav konta",
		"SendNoticeToOrder": "Zasílání připomínky na objednání obědů",
		"SbwEnabled": "Aktivní",
		"BalanceWarningDay": "Den odeslání v měsíci",
		"SendNoticeToOrderDayBefore": "Počet dnů před termínem",
		"SendBalanceWarningSuccess": "Nastavení zasílání upozornění na záporný stav konta bylo úspěšně uloženo.",
		"SendNoticeToOrderSuccess": "Nastavení zasílání připomínky na obědnání obědů bylo úspěšně uloženo.",
		"SaveSuccess": "Nastavení bylo úspěšně uloženo.",
		"SendReportTitle": "Odeslání reportu",
		"UserSendLimit": "Limit pro odeslání",
		"AdminSendLimit": "Limit odeslání adminem"
	},
	"Units": {
		"Money": "Kč"
	},
	"Menu": {
		"Courses": "Zájmové kroužky",
		"Teacher": "Učitel",
		"Price": "Cena",
		"Capacity": "Obsazenost"
	},
	"Teacher": {
		"Title": "Učitel",
		"Name": "Jméno",
		"Photo": "Fotka",
		"LoadPhoto": "Načíst fotku",
		"EditSuccess": "Učitel byl úspěšně upraven.",
		"AddSuccess": "Učitel byl úspěšně vytvořen.",
		"DeleteSuccess": "Učitel byl úspěšně smazán.",
		"ConfirmDelete": "Opravdu chcete smazat vybraného učitele?",
		"Email": "E-mail",
		"User": "Uživatel"
	},
	"Img": {
		"UnsupportedFileType": "Nepodporovaný typ souboru",
		"MaxFileSize6": "Maximální povolená velikost souboru je 6MB."
	},
	"Classroom": {
		"Title": "Školní místnost",
		"Name": "Jméno",
		"Photo": "Fotka",
		"LoadPhoto": "Načíst fotku",
		"EditSuccess": "Školní místnost byla úspěšně upravena.",
		"AddSuccess": "Školní místnost byla úspěšně vytvořena.",
		"DeleteSuccess": "Školní místnost byla úspěšně smazána.",
		"ConfirmDelete": "Opravdu chcete smazat vybranou školní místnost?"
	},
	"Course": {
		"Title": "Kroužek",
		"Name": "Název",
		"Teacher": "Učitel",
		"Classroom": "Učebna",
		"Capacity": "Kapacita",
		"Description": "Popis",
		"Alternate": "Náhradníci",
		"OpenFrom": "Registrace otevřena od",
		"Price": "Cena",
		"IsExternal": "Externí kroužek",
		"Equipments": "Pracovní pomůcky",
		"Photo": "Fotka",
		"LoadPhoto": "Načíst fotku",
		"EditSuccess": "Kroužek byl úspěšně upraven.",
		"AddSuccess": "Kroužek byl úspěšně vytvořen.",
		"ConfirmDelete": "Opravdu chcete smazat vybraný kroužek?",
		"DeleteSuccess": "Kroužek byl úspěšně smazán.",
		"Group": "Filtr na skupiny",
		"Lessons": "Termíny lekcí",
		"LessonCount": "Počet lekcí",
		"CreateLessons": "Vygenerovat",
		"Register": "Registrace",
		"DoRegister": "Registrovat",
		"Age": "Věkové omezení",
		"All": "Bez omezení",
		"Free": "Zadarmo",
		"OpenRegInfo": "Registrace bude otevřena za {ts}.",
		"Student": "Student",
		"NoStudentsInfo": "K vašemu účtu není přidělen žádný student.",
		"RegisterSuccess": "Student byl úspěšně zaregistrován.",
		"StudentAlreadyRegistered": "Student {student} je již registrován.",
		"CapacityReached": "Kapacita kroužku byla naplněna. Další registrace není možná.",
		"Email": "E-mail"
	},
	"Register": {
		"Title": "Registrace",
		"Course": "Kroužek",
		"Student": "Student",
		"From": "Od",
		"To": "Do",
		"Alternate": "Náhradník",
		"ConfirmDelete": "Opravdu chcete smazat vybranou registraci?",
		"ConfirmForceDelete": "Pro vybranou registraci již existují závislá data (transakce nebo docházka). Zvažte, zda není vhodnější provést ukončení registrace. V případě smazání registrace budou smazána i závislá data. Opravdu to chcete udělat?",
		"ConfirmClose": "Opravdu chcete ukončit vybranou registraci?",
		"RegisterSuccess": "Registrace byla úspěšně vytvořena.",
		"DuplicateRecord": "Registrace pro vybraný kroužek a studenta již existuje."
	},
	"Students": {
		"Title": "Studenti",
		"Name": "Jméno",
		"User": "Uživatelský účet",
		"Group": "Skupina",
		"Modal": {
			"Title": "Student"
		},
		"Free": "All inclusive",
		"Balance": "Stav konta",
		"PaymentId": "Variabilní symbol",
		"IsDiner": "Strávník",
		"ImportDiners": "Import strávníků",
		"All": "Vše"
	},
	"Err": {
		"System": "Neočekávaná chyba. Kontaktujte prosím správce systému.",
		"UserIsTeacher": "Uživatel je již nastaven jako učitel."
	},
	"UserBilling": {
		"Student": "Student",
		"Balance": "Stav účtu",
		"Date": "Datum",
		"Type": "Typ",
		"Note": "Poznámka",
		"Amount": "Množství",
		"Course": "Kroužek",
		"Payment": "Platba",
		"Pay": "Zaplatit",
		"Modal": {
			"Title": "Platební údaje",
			"Number": "Číslo účtu",
			"Symbol": "Variabilní symbol",
			"Amount": "Částka",
			"Ss": "Specifický symbol"
		}
	},
	"TransactionType": {
		"0": "Platba",
		"1": "Vklad",
		"2": "Storno",
		"3": "Vratka"
	},
	"Week": {
		"Course": "Kroužek",
		"Time": "Čas",
		"Students": "Studenti"
	},
	"Attendance": {
		"Student": "Student",
		"Presence": "Přítomnost",
		"Lessons": "Lekce"
	},
	"Payments": {
		"Date": "Datum",
		"Type": "Typ",
		"Student": "Student",
		"Course": "Kroužek",
		"Note": "Poznámka",
		"Amount": "Částka"
	},
	"Overview": {
		"Time": "Čas",
		"Course": "Kroužek",
		"Student": "Student",
		"Register": "Registrovat",
		"NoTime": "není",
		"Alternate": "náhradník"
	},
	"LectorEmail": {
		"Title": "Zaslat e-mail"
	},
	"Form": {
		"Title": "Denní komunikace",
		"Date": "Datum",
		"Student": "Student",
		"Group": "Třída",
		"AllDayAbsence": "Celodenní absence",
		"LongTermAbsence": "Dlouhodobá absence",
		"From": "Od",
		"To": "Do",
		"LateArrival": "Pozdní příchod",
		"EarlyDeparture": "Dřívější odchod",
		"UnauthorizedPickUp": "Vyzvednutí dítěte neautorizovanou osobou",
		"UnauthorizedPickUpName": "Jméno a příjmení",
		"UnauthorizedPickUpId": "Číslo občanského průkazu nebo pasu",
		"Lice": "Vši",
		"Infection": "Onemocnění (podezření i potvrzené onemocnění)",
		"Childcare": "Družina",
		"ChildcareInfo": "Jen pro PRIMARY a ELEMENTARY. Studenti TODDLER programu se přihlašují ústně u I. Šket.",
		"AloneDeparture": "Prohlašuji, že můj syn/dcera (žák Elementary programu) může dnes opustit školu bez doprovodu",
		"Other": "Ostatní důležitá sdělení",
		"SuccessSend": "Report byl úspěšně odeslán",
		"InvalidDateError": "Report nelze odeslat zpětně. Report lze odeslat maximálně do {time} téhož dne.",
		"CourseAbsence": "Absence kroužky",
		"Temperature": "Zvýšená teplota",
		"TemperatureInfo": "Za zvýšenou teplotu se považuje tělesná teplota vyšší než 37,5°C",
		"Parasites": "Parazité",
		"Homeworks": "Požaduji domácí úkoly po dobu absence",
		"HomeworksInfo": "Platí pro žáky Elementary Programu",
		"Gdpr": "Data uvedená ve formuláři jsou zpracovávána v souladu s GDPR.",
		"DateInfo": "Formulář je možné odeslat <strong>pouze do {time} téhož dne</strong>. Pro odbavení požadavků týkajících se dnešního dne po vypršení termínu, prosím kontaktujte třídní učitele nebo koordinátory. V bezodkladných případech volejte prosím školní kancelář.",
		"HwInfo": "Spojte se prosím s učitelem vašeho dítěte e-mailem a domluvte se s ním na domácích úkolech.",
		"Submited": "Formulář odeslán",
		"SubmitText": "Děkujeme za odeslání žádosti prostřednictvím našeho komunikačního formuláře. Toto slouží jako potvrzení o přijetí. Následný e-mail obdržíte, pouze pokud jste zadali dotaz nebo pokud došlo k problému s vaší žádostí. V takovém případě byste měli obdržet odpověď do 24 pracovních hodin."
	},
	"History": {
		"Title": "Historie",
		"Date": "Datum",
		"Student": "Student"
	},
	"Report": {
		"UnauthorizedPickUp": "Vyzvednutí neautorizovanou osobou",
		"AloneDeparture": "Odchod bez doprovodu",
		"Other": "Ostatní sdělení",
		"Infection": "Onemocnění",
		"Homeworks": "Domácí úkoly",
		"OtherGroup": "Ostatní",
		"Date": "Datum"
	}
};

export default cs;