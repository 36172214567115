const en = {
	"Btn": {
        "Add": "Add",
        "Update": "Update",
        "Delete": "Delete",
		"Cancel": "Cancel",
		"Save": "Save",
		"Export": "Export",
		"Import": "Import",
		"Close": "Close",
		"Select": "Select",
		"Send": "Send",
		"Print": "Print",
		"Back": "Back",
		"Create": "Create",
		"Finish": "Finish",
		"Reset": "Reset"
	},
	"Codelist": {
		"All": "< all >",
		"ChoseDiner": "< chose diner >",
		"Yes": "Yes",
		"No": "No"
	},
	"Form": {
		"Title": "Daily communication",
		"Date": "Date (dd. mm. yyyy)",
		"Student": "Student",
		"Group": "Group",
		"AllDayAbsence": "Full day absence",
		"LongTermAbsence": "Long-term absence",
		"From": "From",
		"To": "To",
		"LateArrival": "Late arrival",
		"EarlyDeparture": "Early Departure",
		"UnauthorizedPickUp": "Pick up by an unauthorized person",
		"UnauthorizedPickUpName": "Full name",
		"UnauthorizedPickUpId": "Identity card or passport number",
		"Lice": "Lice",
		"Infection": "Disease (suspicion and confirmed illness)",
		"Childcare": "Childcare",
		"ChildcareInfo": "Only for PRIMARY and ELEMENTARY. Students of the TODDLER program register orally with I. Šket.",
		"AloneDeparture": "I confirm that my child (Elementary student) can leave school unaccompanied at the end of the school day",
		"Other": "Other important messages",
		"SuccessSend": "The report was sent successfully.",
		"InvalidDateError": "The report cannot be sent back. The report can be sent no later than {time} on the same day.",
		"CourseAbsence": "Enrichment absence",
		"Temperature": "Elevated temperature",
		"TemperatureInfo": "Body temperature above 37.5°C is considered to be elevated temperature.",
		"Parasites": "Parasitic infection",
		"Homeworks": "I want homework during absence",
		"HomeworksInfo": "Applies to Elementary Program students",
		"Gdpr": "The data provided in the form are processed in accordance with the GDPR.",
		"DateInfo": "The form can only be submitted <strong>until {time} on the same day</strong>. To handle today's requests after the deadline, please contact the teacher and/or Area Coordinator.  In urgent cases, please call the office.",
		"HwInfo": "Please contact your child's teacher by email to arrange homework.",
		"Submited": "Form submitted",
		"SubmitText": "Thank you for submitting your request via our communication form. Please note, this serves as your confirmation of receipt. You will only receive a follow-up email if you have submitted a question or if there's an issue with your request. In this case, you should receive a response within 24 business hours."
	},
    "Header": {
		"About": {
			"Title": "About",
			"Name": "Name",
			"App": "IMSP Lunch system",
			"Version": "Version",
			"Copyright": "© 2019 Copyright by IMSP. All right reserved.",
			"By": "Created by"
		},
		"Form": "Form",
		"History": "History",
        "Login": "Login",
        "Logout": "Logout",
		"Report": "Report",
		"Settings": "Settings"
    },
	"History": {
		"Title": "Communication history",
		"Date": "Date",
		"Student": "Student"
	},
	"Report": {
		"UnauthorizedPickUp": "Pickup by unauthorized person",
		"AloneDeparture": "Unaccompanied departure",
		"Other": "Other communications",
		"Infection": "Disease",
		"Homeworks": "Homework",
		"OtherGroup": "Other",
		"Date": "Date"
	},
	"Settings": {
		"SaveSuccess": "Settings saved successfully.",
		"SendReportTitle": "Sending a report",
		"UserSendLimit": "Send limit",
		"AdminSendLimit": "Admin send limit"
	},
    "Units": {
        "Money": "CZK"
	},
	"Filter": {
		"Group": "Group"
	}
};

export default en;