import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { parse } from 'qs';

import { ApiService } from '../../../services/ApiService';
import { toModalDateTime, toStandardDmySpan, parseDate } from '../../../utils/date';

import './report.scss';
import { getData, renderTable } from './Report';


class ReportPrint extends React.Component {
    static propTypes = {
        location: PropTypes.object
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.query = parse(props.location.search.substr(1));
        console.log('groupFilter', this.query.groupFilter);
        this.state = {
            date: new Date(parseDate(this.query.date)),
            reports: [],
            printShown: false,
            groupFilter: this.getGroupFilter(this.query)
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    getGroupFilter(query) {
        let result = [];

        if (query.groupFilter) {
            if (Array.isArray(query.groupFilter) && query.groupFilter.length) {
                result = query.groupFilter.map(o => ({ value: o }));
            } else {
                result = [{ value: query.groupFilter }];
            }
        }

        // console.log('result', result);
        return result;
    }

    componentWillMount() {
        this.loadReports();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadReports(date) {
        date = date || this.state.date;
        this.api.getReports(toModalDateTime(date))
            .then(response => {
                if (this.mounted) {
                    let reports = response.data || [];
                    this.setState({ reports }, () => {
                        this.runPrint();
                    });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    onDateChanged(date) {
        this.setState({ date }, () => this.loadReports());
    }

    runPrint() {
        console.log('run print');

        if (!this.state.printShown) {

            if (this.mounted) {
                this.setState({
                    printShown: true
                });
            }

            setTimeout(() => {
                window.print();

                setTimeout(() => {
                    window.close();
                }, 50);
            }, 50);
        }
    }

    landscapeOrientation() {
        return (
            <style type="text/css">
                {"@media print{@page {size: landscape; margin: 4mm; max-width: 100%;}}"}
            </style>
        );
    };

    render() {
        let { translate } = this.context;
        let { reports, date, groupFilter } = this.state;

        let data = getData(reports, date, groupFilter);

        return (
            <div className="page-container report-print">
                {this.landscapeOrientation()}
                <div className="header">
                    <div className="title">{this.context.translate('Form.Title')}</div>
                    <div className="date">
                        <div className="label">{this.context.translate('Filter.Date')}:</div>
                        <div className="value">{toStandardDmySpan(date)}</div>
                    </div>
                </div>
                <div className="page-content">
                    {renderTable(data, translate)}
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        location: state.router.location
    }),
    {}
)(ReportPrint);