import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import { arraySort } from '../../../utils/sort';
import { ApiService } from '../../../services/ApiService';
import { today, toModalDateTime, toLunchShortDate, toTimeHmmFormat, toDate, toPeriodDate, toModalDate } from '../../../utils/date';

import './report.scss';
import { DateControl } from '../../../components/dateControl/dateControl';


export const getData = (reports, date, groupFilter) => {
    let ltReports = reports.map(o => toDate(o.date).getTime() === date.getTime() ? o : {
        longTermAbsence: o.longTermAbsence,
        longTermAbsenceFrom: o.longTermAbsenceFrom,
        longTermAbsenceTo: o.longTermAbsenceTo,
        homeworks: o.homeworks,
        id: o.id,
        studentId: o.studentId,
        studentName: o.studentName,
        groupId: o.groupId,
        groupName: o.groupName,
        groupOrder: o.groupOrder
    });

    // console.log('xxx', { groupFilter, reports });
    if (groupFilter && groupFilter.length) {
        reports = reports.filter(o => _.some(groupFilter, { value: o.groupId }));
    }

    let groups = reports.reduce((r, c) => _.some(r, { groupId: c.groupId }) ? r : [...r, { ...c, type: 'group', id: c.groupId }], []);
    groups = arraySort(groups, 'groupOrder');

    let result = [];
    groups.forEach(g => {
        result.push(g);
        arraySort(ltReports.filter(o => o.groupId === g.groupId), 'studentName').forEach(o => result.push(o));
    });
    return result;
}

export const renderTable = (data, translate, options) => {
    options = options || {};

    return (
        <div className="sticky-table-container">
            <table className="imsp-table report-table">
                <colgroup>
                    <col className="col-name" />
                    <col className="col-allDayAbsence" />
                    <col className="col-longTermAbsenceFrom" />
                    <col className="col-longTermAbsenceTo" />
                    {/*<col className="col-homeworks" />*/}
                    <col className="col-lateArrival" />
                    <col className="col-earlyDeparture" />
                    <col className="col-courseAbsence" />
                    <col className="col-infections" />
                    <col className="col-temperature" />
                    {options.showLices &&
                        <col className="col-lice" />
                    }
                    {options.showParasites &&
                        <col className="col-parasites" />
                    }
                    <col className="col-unauthorizedPickUp" />
                    <col className="col-childcare" />
                    <col className="col-aloneDeparture" />
                    <col className="col-other" />
                </colgroup>
                <thead>
                    <tr>
                        <th className="col-name">{translate('Form.Student')}</th>
                        <th className="col-allDayAbsence">{translate('Form.AllDayAbsence')}</th>
                        <th className="col-longTermAbsenceFrom" colSpan="2">{translate('Form.LongTermAbsence')}</th>
                        {/*<th className="col-homeworks">{translate('Report.Homeworks')}</th>*/}
                        <th className="col-lateArrival">{translate('Form.LateArrival')}</th>
                        <th className="col-earlyDeparture">{translate('Form.EarlyDeparture')}</th>
                        <th className="col-courseAbsence">{translate('Form.CourseAbsence')}</th>
                        <th className="col-infections">{translate('Report.Infection')}</th>
                        <th className="col-temperature">{translate('Form.Temperature')}</th>
                        {options.showLices &&
                            <th className="col-lice">{translate('Form.Lice')}</th>
                        }
                        {options.showParasites &&
                            <th className="col-parasites">{translate('Form.Parasites')}</th>
                        }
                        <th className="col-unauthorizedPickUp">{translate('Report.UnauthorizedPickUp')}</th>
                        <th className="col-childcare">{translate('Form.Childcare')}</th>
                        <th className="col-aloneDeparture">{translate('Report.AloneDeparture')}</th>
                        <th className="col-other">{translate('Report.Other')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(o => {
                        if (o.type === 'group') {
                            let sp = 13;
                            if (options.showLices)
                                sp++;
                            if (options.showParasites)
                                sp++;
                            return (
                                <tr key={o.groupId}>
                                    <td className="col-group" colSpan={sp}>{o.groupName ?? translate('Report.OtherGroup')}</td>{/*colSpan="16"*/}
                                </tr>
                            );
                        } else {
                            return (
                                <tr key={o.id}>
                                    <td className="col-name">{o.studentName}</td>
                                    <td>{o.allDayAbsence && <i className="fa fa-check colored" aria-hidden="true"></i>}</td>
                                    <td>{o.longTermAbsence ? toLunchShortDate(o.longTermAbsenceFrom) : ''}</td>
                                    <td>{o.longTermAbsence ? toLunchShortDate(o.longTermAbsenceTo) : ''}</td>
                                    {/*<td>{o.homeworks && <i className="fa fa-check colored" aria-hidden="true"></i>}</td>*/}
                                    <td>{o.lateArrival ? toTimeHmmFormat(o.lateArrival) : ''}</td>
                                    <td>{o.earlyDeparture ? toTimeHmmFormat(o.earlyDeparture) : ''}</td>
                                    <td>{o.courseAbsence && <i className="fa fa-check colored" aria-hidden="true"></i>}</td>
                                    <td className="col-infections">{o.infections}</td>
                                    <td>{o.temperature && <i className="fa fa-check colored" aria-hidden="true"></i>}</td>
                                    {options.showLices &&
                                        <td>{o.lice && <i className="fa fa-check colored" aria-hidden="true"></i>}</td>
                                    }
                                    {options.showParasites &&
                                        <td>{o.parasites && <i className="fa fa-check colored" aria-hidden="true"></i>}</td>
                                    }
                                    <td className="col-unauthorizedPickUp">{o.unauthorizedPickUp ? `${o.unauthorizedPickUpName} | ${o.unauthorizedPickUpId}` : ''}</td>
                                    <td>{o.childcare && <i className="fa fa-check colored" aria-hidden="true"></i>}</td>
                                    <td>{o.aloneDeparture && <i className="fa fa-check colored" aria-hidden="true"></i>}</td>
                                    <td className="col-other">{o.other}</td>
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </table>
        </div>
    );
}

class Report extends React.Component {
    static propTypes = {
        language: PropTypes.string.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            date: today(),
            reports: [],
            groupFilter: [],
            groups: []
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('Header.Report');
        this.loadReports();
        this.loadGroups();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadGroups() {
        this.api.getGroups()
            .then(response => {
                let groups = response.data || [];
                if (this.mounted) {
                    this.setState({ groups });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    loadReports(date) {
        date = date || this.state.date;
        this.api.getReports(toModalDateTime(date))
            .then(response => {
                if (this.mounted) {
                    let reports = response.data || [];
                    this.setState({ reports });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    onDateChanged(date) {
        this.setState({ date }, () => this.loadReports());
    }

    export() {
        let { date, groupFilter } = this.state;
        let { language } = this.props;

        this.api.exportReports({ date: toModalDate(date), lang: language, groupFilter: (groupFilter || []).map(o => o.value) })
            .then(response => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `communication-report-${toPeriodDate(date)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.log('Export report error', error);
            })
    }

    getGroupFilterOptions(groups) {
        // let result = [
        //     { id: '', name: this.context.translate('Codelist.All') },
        //     ...arraySort([...(groups || [])], 'name')
        // ];
        let result = (groups || []).map(o => ({ value: o.id, label: o.name }));
        arraySort(result, 'name');
        return result;
    }

    getPrintUrl(date, groupFilter) {
        let result = `/view/report/print?date=${toModalDate(date)}`;
        if (groupFilter && groupFilter.length) {
            groupFilter.forEach(o => {
                result += `&groupFilter=${o.value}`;
            });
        }
        // console.log('printUrl', result);
        return result;
    }

    render() {
        let { translate } = this.context;
        let { reports, date, groups, groupFilter } = this.state;

        let data = getData(reports, date, groupFilter);
        let printUrl = this.getPrintUrl(date, groupFilter);
        let groupFilterOptions = this.getGroupFilterOptions(groups);

        return (
            <div className="page-container">
                <div className="page-menu">
                    <div className="filter">
                        <div className="filter-group">
                            <label>{translate('Report.Date')}</label>
                            <DateControl
                                date={date}
                                onChange={date => this.onDateChanged(date)}
                            />
                        </div>
                        <div className="filter-group">
                            <label>{translate('Filter.Group')}</label>
                            <Select
                                options={groupFilterOptions}
                                isMulti
                                onChange={groupFilter => this.setState({ groupFilter })}
                                menuPortalTarget={document.querySelector('body')}
                                placeholder={translate('Codelist.All')}
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        <a
                            className="btn btn-imsp"
                            href={printUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{this.context.translate('Btn.Print')}</a>
                        <div
                            className="btn btn-imsp"
                            onClick={() => this.export()}
                        >{this.context.translate('Btn.Export')}</div>
                    </div>
                </div>
                <div className="page-content">
                    {renderTable(data, translate)}
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        language: state.localize.language
    }),
    {}
)(Report);