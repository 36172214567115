import _ from 'lodash';
import * as toastr from 'toastr';
import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ApiService } from '../../../services/ApiService';
import { toStandardDmySpan, toTimeHmmFormat } from '../../../utils/date';
import { defaultReport } from '../form/CommForm';


class EmptyComponent extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            reports: {},
            selectedReport: ''
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadReports();
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.onTitle) this.props.onTitle('History.Title');
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadReports() {
        this.api.getMyReports(null, null, 10)
            .then(response => {
                if (this.mounted) {
                    let reports = _.mapKeys(response.data || {}, 'id');
                    this.setState({ reports });
                }
            })
            .catch(error => {
                if (this.mounted) {
                    toastr.error(this.context.translate('Err.System'));
                }
            });
    }

    select(id) {
        let { selectedReport } = this.state;

        selectedReport = id === selectedReport ? '' : id;
        this.setState({ selectedReport });
    }

    render() {
        let { translate } = this.context;
        let { reports, selectedReport } = this.state;

        return (
            <div className="user-page-container">
                <div className="structure-list">
                    <div className="header">
                        <div className="pc-style">
                            <div className="line">
                                <div className="column f-100 ta-r">{translate('History.Date')}</div>
                                <div className="column fill ta-r">{translate('History.Student')}</div>
                            </div>
                        </div>
                        <div className="mob-style">
                            <div className="line">
                                <div className="column f-100 ta-r">{translate('History.Date')}</div>
                                <div className="column fill ta-r">{translate('History.Student')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        {_.map(reports, o => {
                            let selected = o.id === selectedReport;

                            return (
                                <div
                                    className={classnames('item', { selected })}
                                    onClick={() => this.select(o.id)}
                                    key={o.id}
                                >
                                    <div className="pc-style">
                                        <div className="item-row">
                                            <div className="line">
                                                <div className="value f-100 ta-r">{toStandardDmySpan(o.date)}</div>
                                                <div className="value fill ta-r">{o.studentName}</div>
                                            </div>
                                        </div>
                                        {selected &&
                                            <div className="item-detail">
                                                <div className="multi-row">
                                                    {o.allDayAbsence !== defaultReport.allDayAbsence &&
                                                        <div className="multi-row-item">{translate('Form.AllDayAbsence')}</div>
                                                    }
                                                    {o.lateArrival !== defaultReport.lateArrival &&
                                                        <div className="multi-row-item">{translate('Form.LateArrival')}: {toTimeHmmFormat(o.lateArrival)}</div>
                                                    }
                                                    {o.earlyDeparture !== defaultReport.earlyDeparture &&
                                                        <div className="multi-row-item">{translate('Form.EarlyDeparture')}: {toTimeHmmFormat(o.earlyDeparture)}</div>
                                                    }
                                                    {o.courseAbsence !== defaultReport.courseAbsence &&
                                                        <div className="multi-row-item">{translate('Form.CourseAbsence')}</div>
                                                    }
                                                    {o.childcare !== defaultReport.childcare &&
                                                        <div className="multi-row-item">{translate('Form.Childcare')}</div>
                                                    }
                                                    {o.lice !== defaultReport.lice &&
                                                        <div className="multi-row-item">{translate('Form.Lice')}</div>
                                                    }
                                                    {o.parasites !== defaultReport.parasites &&
                                                        <div className="multi-row-item">{translate('Form.Parasites')}</div>
                                                    }
                                                    {o.temperature !== defaultReport.temperature &&
                                                        <div className="multi-row-item">{translate('Form.Temperature')}</div>
                                                    }
                                                </div>
                                                {o.longTermAbsence !== defaultReport.longTermAbsence &&
                                                    <div className="single-row">{translate('Form.LongTermAbsence')}: {toStandardDmySpan(o.longTermAbsenceFrom || new Date())} - {toStandardDmySpan(o.longTermAbsenceTo || new Date())}</div>
                                                }
                                                {o.unauthorizedPickUp !== defaultReport.unauthorizedPickUp &&
                                                    <div className="single-row">{translate('Form.UnauthorizedPickUp')}: {o.unauthorizedPickUpName} / {o.unauthorizedPickUpId}</div>
                                                }
                                                {o.infections !== defaultReport.infections &&
                                                    <div className="single-row">{translate('Form.Infection')}: {o.infections}</div>
                                                }
                                                {o.aloneDeparture !== defaultReport.aloneDeparture &&
                                                    <div className="single-row">{translate('Form.AloneDeparture')}</div>
                                                }
                                                {o.homeworks !== defaultReport.homeworks &&
                                                    <div className="single-row">{translate('Form.Homeworks')}</div>
                                                }
                                                {o.other !== defaultReport.other &&
                                                    <div className="single-row">{translate('Form.Other')}: {o.other}</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="mob-style">
                                        <div className="item-row">
                                            <div className="line">
                                                <div className="value f-100 ta-r">{toStandardDmySpan(o.date)}</div>
                                                <div className="value fill ta-r">{o.studentName}</div>
                                            </div>
                                        </div>
                                        {selected &&
                                            <div className="item-detail">
                                                {o.allDayAbsence !== defaultReport.allDayAbsence &&
                                                    <div className="single-row">{translate('Form.AllDayAbsence')}</div>
                                                }
                                                {o.lateArrival !== defaultReport.lateArrival &&
                                                    <div className="single-row">{translate('Form.LateArrival')}: {toTimeHmmFormat(o.lateArrival)}</div>
                                                }
                                                {o.earlyDeparture !== defaultReport.earlyDeparture &&
                                                    <div className="single-row">{translate('Form.EarlyDeparture')}: {toTimeHmmFormat(o.earlyDeparture)}</div>
                                                }
                                                {o.courseAbsence !== defaultReport.courseAbsence &&
                                                    <div className="single-row">{translate('Form.CourseAbsence')}</div>
                                                }
                                                {o.childcare !== defaultReport.childcare &&
                                                    <div className="single-row">{translate('Form.Childcare')}</div>
                                                }
                                                {o.longTermAbsence !== defaultReport.longTermAbsence &&
                                                    <div className="single-row">{translate('Form.LongTermAbsence')}: {toStandardDmySpan(o.longTermAbsenceFrom || new Date())} - {toStandardDmySpan(o.longTermAbsenceTo || new Date())}</div>
                                                }
                                                {o.unauthorizedPickUp !== defaultReport.unauthorizedPickUp &&
                                                    <div className="single-row">{translate('Form.UnauthorizedPickUp')}: {o.unauthorizedPickUpName} / {o.unauthorizedPickUpId}</div>
                                                }
                                                {o.aloneDeparture !== defaultReport.aloneDeparture &&
                                                    <div className="single-row">{translate('Form.AloneDeparture')}</div>
                                                }
                                                {o.homeworks !== defaultReport.homeworks &&
                                                    <div className="single-row">{translate('Form.Homeworks')}</div>
                                                }
                                                {o.infections !== defaultReport.infections &&
                                                    <div className="single-row">{translate('Form.Infection')}: {o.infections}</div>
                                                }
                                                {o.lice !== defaultReport.lice &&
                                                    <div className="single-row">{translate('Form.Lice')}</div>
                                                }
                                                {o.parasites !== defaultReport.parasites &&
                                                    <div className="single-row">{translate('Form.Parasites')}</div>
                                                }
                                                {o.temperature !== defaultReport.temperature &&
                                                    <div className="single-row">{translate('Form.Temperature')}</div>
                                                }
                                                {o.other !== defaultReport.other &&
                                                    <div className="single-row">{translate('Form.Other')}: {o.other}</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(EmptyComponent);