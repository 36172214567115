import * as React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { nextDay, prevDay } from '../../utils/date';

import './dateControl.scss';


export const DateControl = ({ date, onChange, onChangeRaw }) => {
    return (
        <div className="date-control-container">
            <div
                className="btn btn-light prev-date-btn"
                onClick={() => { if (onChange) onChange(prevDay(date)) }}
            ><i className="fa fa-angle-left" aria-hidden="true"></i></div>
            <DatePicker
                className="form-control"
                dateFormat={["d. M. yyyy", "d.M.yyyy", "d. M.yyyy", "d.M. yyyy"]}
                selected={date}
                onChangeRaw={onChangeRaw}
                onChange={date => { if (onChange) onChange(date) }}
                placeholderText="d. M. yyyy"
                strictParsing={true}
            />
            <div
                className="btn btn-light next-date-btn"
                onClick={() => { if (onChange) onChange(nextDay(date)) }}
            ><i className="fa fa-angle-right" aria-hidden="true"></i></div>
        </div>
    );
}