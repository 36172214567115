import * as toastr from 'toastr';
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { ApiService } from '../../../services/ApiService';

import './settings.scss';

// Edit state
const ES_NONE = '';
const ES_DATA = 'data';


class Settings extends React.Component {
    static propTypes = {
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            edit: {
                state: ES_NONE,
                sendLimit: '',
                adminSendLimit: ''
            }
        };
        this.mounted = false;
        this.api = new ApiService();
    }

    componentWillMount() {
        this.loadData();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadData() {
        this.api.getSettings()
            .then(response => {
                if (this.mounted) {
                    this.setState({ data: response.data });
                }
            })
            .catch(error => {
                console.log('Settings.loadData error', error);
            })
    }

    saveData() {
        if (this.validateData()) {
            let { sendLimit, adminSendLimit } = this.state.edit;

            this.api.setSettings({ sendLimit, adminSendLimit })
                .then(response => {
                    this.loadData();
                    this.setState({ edit: { ...this.state.edit, state: '' } });
                    toastr.success(this.context.translate('Settings.SaveSuccess'));
                })
                .catch(error => {
                    console.log('Settings.saveData', error);
                    toastr.error(error);
                });
        }
    }

    validateData() {
        return true;
    }

    render() {
        let { data, edit } = this.state;
        let { sendLimit, adminSendLimit } = data;

        let editData = edit.state === ES_DATA;

        return (
            <div className="settings-page">
                <div className="settings-page-content">
                    <div className="section">
                        <div className="section-title">{this.context.translate('Settings.SendReportTitle')}</div>
                        <div className="section-content">
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.UserSendLimit')}</div>
                                {editData ?
                                    <input
                                        className="form-control value"
                                        type="text"
                                        autoFocus
                                        value={edit.sendLimit || ''}
                                        onChange={e => this.setState({ edit: { ...edit, sendLimit: e.target.value } })}
                                    />
                                    :
                                    <div className="value ta-r">{sendLimit}</div>
                                }
                            </div>
                            <div className="item-group">
                                <div className="label">{this.context.translate('Settings.AdminSendLimit')}</div>
                                {editData ?
                                    <input
                                        className="form-control value"
                                        type="text"
                                        value={edit.adminSendLimit || ''}
                                        onChange={e => this.setState({ edit: { ...edit, adminSendLimit: e.target.value } })}
                                    />
                                    :
                                    <div className="value ta-r">{adminSendLimit}</div>
                                }
                            </div>
                            <div className="buttons">
                                {editData ?
                                    <>
                                        <div
                                            className="btn btn-default"
                                            onClick={() => this.setState({ edit: { ...edit, state: '' } })}
                                        >{this.context.translate('Btn.Cancel')}</div>
                                        <div
                                            className="btn btn-imsp"
                                            onClick={() => this.saveData()}
                                        >{this.context.translate('Btn.Save')}</div>
                                    </>
                                    :
                                    <div
                                        className="btn btn-imsp"
                                        onClick={() => this.setState({ edit: { ...edit, state: ES_DATA, sendLimit, adminSendLimit } })}
                                    >{this.context.translate('Btn.Update')}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(
    state => ({
        // profile: state.profile,
    }),
    {}
)(Settings);